.pagination {
    padding: 20px 0;
    margin-top: 10px;

    &-info {
        font-size: $size-font-s;
        text-align: right;
    }

    &-controllers {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
    }

    &-prev {
        .page-button{
            margin-left: 0;
        }
    }

    &-next {
        .page-button{
            margin-right: 0;
        }
    }

    .page-button {
        @extend %button;

        min-width: 46px;
        margin: 0 3px;
        padding: 7px 15px;

        &--active {
            color: #fff;
            background-color: $color-orange;
        }
        
        &:disabled {
            color: lighten($color-text-gray, 20%);
            background-color: $color-upload-bg;
        }

        &:hover:not(.page-button--active):not(:disabled) {
            color: #fff;
            background-color: $color-text-midgray;
        }
    }
}