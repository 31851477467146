.table-type-listing{
    margin: 10px 0;

    .ReactTable{
        border: none;

        .rt-table{
            font: 400 1rem "Source Sans Pro", sans-serif;
            color: $color-text-darkgray;
        }

        .rt-thead{
            min-width: 500px;
            color: $color-blue;
            font-weight: 700;
            text-transform: uppercase;
            padding: 10px 0;
            box-shadow: none;
            border-bottom: solid 1px $color-main-bg;

            &.-filters{
                display: none;
            }

            .rt-tr{
                text-align: left;
            }

            .rt-th{
                box-shadow: none;
                border: none;
            }
        }

        .rt-tbody{
            .rt-tr-group {
                border-bottom: solid 1px $color-main-bg;
            }

            .rt-tr{
                padding: 6px 0;

                &.-odd{
                    background: #fff;
                }

                //& .rt-td:first-child{
                //    font-weight: 700;
                //}
            }

            .rt-td{
                border: none;

                & > div:not(.rt-btn-wrapper, .rating-icons--big) {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    height: 100%;
                }
            }
        }
    }
}
