.user-menu {
    &-theme-card {
        display: inline-block;
        background-color: transparent;
        border: 2px solid rgba(#fff, .2);
        border-radius: $size-border-radius-l;
        padding: 14px 25px;
        cursor: pointer;
        @include transition((border-color, background-color), .2s);
    
        &:hover {
            border-color: rgba(#fff, .5);
            background-color: rgba(#fff, .2);
        }
    
        &.active {
            border-color: #fff;
            
            &:hover {
                border-color: #fff;
                background-color: rgba(#fff, .2);
            }
        }
        
        &-color {
            margin: 0 auto 5px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
    
            &.theme-light {
                background-color: lighten($color-text-midgray, 17%);
            }
    
            &.theme-dark {
                background-color: $color-purple;
            }
        }
    
        p {
            font-size: $size-font-xs;
            font-weight: 600;
            text-align: center;
            color: #fff;
        }
    }
    
    &-avatar {
        position: relative;
        border: 3px solid #fff;
        border-radius: 50%;
        background-color: transparent;
        width: 97px;
        height: 97px;
        overflow: hidden;

        &-img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
        }

        button {
            position: relative;
            display: block;
            font: 700 $size-font-xxs $font-titles;
            border: none;
            border-radius: $size-border-radius-xs;
            margin: 0 auto;
            margin-top: 50px;
            background-color: #fff;
            padding: 4px 12px;
            text-transform: uppercase;
            color: $color-picker-brown;
            z-index: 2;
        }
    }

}