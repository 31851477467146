.container-public {
    .auth-block {
        max-width: 650px;

        &-header {
            margin: 0 0 30px;

            h1 {
                margin: 0 0 10px;
                text-align: center;
            }
        }

        &-footer {
            margin-top: 20px;
            text-align: center;
            
            a {
                margin-top: 7px;
                font: 600 $size-font-xs $font-primary;
                color: #fff;
            }
        }

        .form-group {
            .btn-secondary {
                width: 100%;
            }
        }

        .input-text {
            margin: 40px 0;
        }

        input,
        select {
            border-color: $color-midpurple;
            color: $color-lightpurple;
        }
    }

    .register-block {
        width: 960px;
        max-width: 100%;
        margin: 100px auto auto;
        padding: 40px;
        background-color: #fff;
        box-shadow: 0 5px 20px 0 rgba(203,221,232,0.8), 0 3px 3px 0 #D3E5F0;
        border-radius: $size-border-radius;

        &-content {

            &-success {
                padding: 70px 30px 45px;
                border-top: 2px solid #C2E4FC;
                text-align: center;

                h2 {
                    margin: 0 0 20px;
                }

                p {
                    margin: 0 0 10px;
                }

                .btn-primary {
                    display: inline-block;
                    margin: 30px 0 0;
                }
            }

            &-box {
                padding: 30px 0 40px;
            }
        }

        .logo-wrap {
            margin: 0 auto 40px;
        }

        .progress-steps {
            margin: 0 0 40px;

            li {
                &:first-child:before {
                    width: 0;
                }

                &.progress-steps-active {
                    &:before {
                        width: 100%;
                    }
                }
            }
        }
    }

    .logo-wrap {
        width: 180px;
        margin: 60px auto;
    }
}
