input,
select {
    width: 100%;
    padding: 10px 0;
    max-height: 40px;
    border: none;
    border-bottom: 1px solid $color-dark-orange;
    background-color: transparent;
    line-height: 22px;
    font: 600 1rem $font-primary;
    color: #fff;

    &:focus {
        outline: none;
    }

    &::placeholder{
        color: $color-text-lightgray;
    }

    &.input-inactive input::placeholder{
        color: rgba(#000000, 0.2);
    }
}

input[readonly] {
    color: $color-text-darkgray;
    font-weight: 600;
}

.input-text {
    margin: 15px 0;

    input[type="text"],
    input[type="email"],
    input[type="password"] {
        min-height: 50px;
    }
}