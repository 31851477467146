.event {
  &-add {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 12px;
    background-color: #FFF;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 25px 16px;
    width: 100%;
    margin-bottom: 60px;
  }

  &-close {
    position: absolute;
    right: 16px;
    width: 24px;
    height: 24px;
    top: 25px;
    background: $icon-cardclose no-repeat center;
    background-size: contain;
    border: 0;
  }
}