// REGULAR BUTTON
%button {
    display: inline-block;
    font: 700 $size-font-s $font-titles;
    box-sizing: border-box;
    border-radius: $size-border-radius;
    border: none;
    min-width: 200px;
    min-height: 56px;
    text-align: center;
    padding: 9px 20px;
    line-height: 18px;
    word-break: break-word;
    text-decoration: none;

    &[disabled] {
        background-color: $color-text-gray;
        color: #fff;
        cursor: not-allowed;
    }
    &.btn-small {
        min-width:200px
    }

    &.btn-margin {
        margin:5px;
    }
}

.btn-primary {
    @extend %button;

    &:not([disabled]){
        color: #fff;
        background-color: $color-purple;

        &:hover{
            color: #fff;
            background-color: darken($color-purple, 15%);
        }
    }
}

.btn-secondary {
    @extend %button;
    
    &:not([disabled]){
        color: #fff;
        background-color: $color-orange;

        &:hover{
            color: #fff;
            background-color: darken($color-orange, 15%);
        }
    }
}

.btn-tertiary {
    @extend %button;

    &:not([disabled]){
        color: $color-orange;
        background-color: transparent;
        border: 2px solid $color-orange;

        &:hover{
            color: darken($color-orange, 15%);
            background-color: rgba(#000, .1);
            border: 2px solid darken($color-orange, 15%);
        }
    }
}

.btn-close{
    position: relative;
    width: 20px;
    height: 20px;
    border: none;
    background: transparent;

    &::before,
    &::after{
        content: '';
        @include absolute-center-center;
        @include transition(background-color, .25s);
        width: 100%;
        height: 2px;
        background-color: lighten($color-orange, 30%);
    }

    &::before{
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after{
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:hover{
        &::before,
        &::after{
            background-color: $color-orange;
        }
    }
}

.btn-stripped {
    font-size: $size-font-m;
    line-height: 0;
    color: $color-orange;
    position: relative;
    border: none;
    background: transparent;
    @include transition(color, 0.2s);

    &:hover{
        color: $color-text-midgray;
    }
}

.btn-feedback {
    @extend %button;

    width: 100%;
    border-radius: 50px;
    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.1);
    padding: 8px 30px;
    margin: 10px 0;
    min-height: initial;
    
    &::before {
        content: '';
        background: $icon-like-orange no-repeat center;
        background-size: contain;
        margin-right: 10px;
        width: 20px;
        height: 20px;
        padding-left: 20px;
        font-size: 1rem;
    }

    &:not([disabled]){
        color: #fff;
        background: linear-gradient(135.82deg, $color-yellow 0%, $color-lightorange 100%);

        &:focus{
            color: #fff;
            background-color: darken($color-purple, 15%);

            &::before {
                background: $icon-like-purple no-repeat center;
                background-size: contain;
            }
        }
    }
}