.input-radio{
    @extend %radio-checkbox;

    input[type=radio] + label{
        &::before{
            transform: translateY(-50%) scale(0,0);
            top: 50%;
            left: 6px;
            width: 14px;
            height: 14px;
            background-color: $color-blue-tab;
        }
    }

    input[type=radio]{
        min-width: initial;

        &:checked +label::before {
            transform: translateY(-50%) scale(1,1);
        }
    }
}