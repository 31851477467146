.dropdown-menu {
    position: relative;
    min-width: 170px;
    cursor: pointer;

    .dropdown-menu-label {
        display: flex;
        align-items: center;
        background-color: $color-darkpurple;
        border-radius: $size-border-radius-s;
        padding: 16px;

        & > span {
            position: relative;
            font-size: $size-font-xs;
            color: #fff;
            width: 100%;
            font-weight: 700;
            line-height: 18px;
            padding-right: 25px;

            &::after {
                content: '';
                @include absolute-right-center;
                transform: translateY(-60%) rotate(45deg);
                width: 8px;
                height: 8px;
                border-left: 2px solid #fff;
                border-bottom: 2px solid #fff;
                @include transition((color, transform), .2s);
            }
        }
    }

    .dropdown-menu-list {
        position: absolute;
        width: 100%;
        background-color: $color-darkpurple;
        padding: 0 16px 16px;
        border-bottom-right-radius: $size-border-radius-s;
        border-bottom-left-radius: $size-border-radius-s;
        z-index: 99;
        @include transition(padding, .3s, cubic-bezier(.73,.32,.34,1.5));

        li {
            overflow: hidden;
            height: 35px;
            white-space: nowrap;
            @include transition(height, .3s, cubic-bezier(.73,.32,.34,1.5));

            a {
                text-decoration: none;
                color: #fff;
                font-size: $size-font-xs;
                font-weight: 500;
                line-height: 35px;
                width: 100%;
                display: block;

                &.active {
                    color: $color-orange;
                }
            }

            &:hover a {
                color: $color-orange;
            }
        }
    }

    &:hover {
        .dropdown-menu-label {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            & > span {
                &::after {
                    transform: translateY(-70%) rotate(-45deg);
                    border-color: $color-orange;
                }
            }
        }
    }
}

.dropdown-menu:not(:hover) > .dropdown-menu-list {
    padding: 0 20px;

    > li {
        visibility: hidden;
        height: 0;
    }
}
