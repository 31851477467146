html, body, #app, .main  {
  font: 400 $size-font-s $font-primary;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

body {
  position: relative;
  @extend %scrollbar;

  background-color: $color-inner-bg;

  &.no-scroll {
    overflow-y: hidden;
  }

  &.public {
    background: linear-gradient(180deg, saturate($color-purple, 27%), darken($color-lightpurple, 35%));

    &:before {
      content: '';
      position: absolute;
      background: $vector-waves no-repeat center;
      background-size: 170% auto;
      top: 30vH;
      left: 0;
      height: 100vH;
      width: 100%;
      transform: translateY(-50%);
      z-index: -1;
    }
  }

  &.menu-open {
    overflow: hidden;

    #app {
      overflow: auto;
      right: 280px;
    }

    #header {
      overflow: hidden;
      right: 280px;
    }

    #footer {
      overflow: hidden;
      right: 280px;
    }

    #sidebar {
      right: 0;
    }
  }
}

#header, #footer {
  position: relative;
  right: 0;
  overflow-x: hidden;
  transition: right 0.3s ease-in-out;
}

#warning-orientation {
  display: none;
  background-color: #ffffff;
  padding: 20px;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vH;
  text-align: center;
}

@media only screen and (hover: none) and (pointer: coarse) and (orientation:landscape){
  .landscape {
    #warning-orientation {
      display: flex !important;
    }

    #app {
      display: none;
    }
  }
}