form {
    padding: 0;
    width: 100%;
}

.form-group {
    &-body {
        display: flex;
        justify-content: space-between;
    }
    
    &-col-2{
        flex: 0 1 45%;
    }
}

.form-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    & > button{
        margin-left: 20px;
    }
}

.form-class {
    &-body-flex {
        display:flex;
        flex-wrap: wrap;
        .form-element-half {
            width:45%;
            display: inline-block;
            margin: 20px 0;
            &:nth-child(2n+1){
                margin-right:10%;
            }
        }
    }

    &-footer {
        display:flex;
        justify-content: space-between;

        &.footer-right {
            justify-content: right;
        }
    }
}
