.horizontal-slider {
  width: 100%;
  max-width: 500px;
  height: 50px;

  &-labels {
    display: flex;
    justify-content: space-between;
    font: 500 $size-font-xxs $font-titles;
    color: $color-text-gray;
  }

  &-track {
    top: 50%;
    transform: translateY(-50%);
    height: 8px;
    background-color: lighten($color-purple, 67%);
    border-radius: $size-border-radius-s;
  }

  &-track-0 {
    background-color: $color-orange;
  }

  &-track-1 {
    height: 9px;
  }

  &-thumb {
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
    line-height: 24px;
    box-shadow: 0 2px 18px 0 rgba(0,0,0,0.15);
  }
}
