#footer {
  z-index: 5;
  position: fixed;
  top: calc(100% - 56px);
  width: 100%;
  height: calc(100% - 250px);
  flex: none;
  @include transition(all, .3s);
  cursor: pointer;

  &.journals-open {
    top: 250px;
  }
}

body.scrolled {
  #footer.journals-open {
    top: 74px;
    height: calc(100% - 74px);
  }
}