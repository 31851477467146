#sidebar {
    position: fixed;
    z-index: 10;
    display: flex;
    width: 280px;
    height: 100%;
    top: 0;
    right: -280px;
    background: linear-gradient(-60deg, #E34624 20%, #FF6E00 120%);
    transition: right .3s ease-in-out;

    .sidebar-inner-wrap {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: auto;
        padding: 40px 20px;
    }

    &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 30px;
        background: linear-gradient(90deg, rgba($color-dark-orange, .6), transparent);
        left: 0;
        top: 0;
        z-index: -1;
    }

    .btn-tertiary {
        margin-bottom: 20px;
        width: 100%;
        color: #fff;
        border-color: #fff;
    }
}