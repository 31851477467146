.svg-inline {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
    fill: currentColor;
}

// .svg-action-loader {
//     align-self: center;
//     position: absolute;

//     &-blue {
//         circle {
//             stroke: $color-blue;
//         }
//     }
// }

// .svg-bulk-action-loader {
//     @extend .svg-action-loader;
//     left: -20px;
// }

// .svg-download-action-loader {
//     @extend .svg-action-loader;
//     font-size: 3rem;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);

//     circle {
//         stroke-width: 4;
//     }
// }

// .svg-icon-single-text {
//     font-size: 1.467rem;
//     vertical-align: middle;
//     margin: 0 5px 0 0;
// }
