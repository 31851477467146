// Quicksand
@font-face {
    font-family: 'Quicksand';
    src: url('../../../assets/fonts/Quicksand/Quicksand-Medium.eot');
    src: url('../../../assets/fonts/Quicksand/Quicksand-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/Quicksand/Quicksand-Medium.ttf') format('truetype'),
        url('../../../assets/fonts/Quicksand/Quicksand-Medium.woff2') format('woff2'),
        url('../../../assets/fonts/Quicksand/Quicksand-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Quicksand';
    src: url('../../../assets/fonts/Quicksand/Quicksand-Bold.eot');
    src: url('../../../assets/fonts/Quicksand/Quicksand-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/Quicksand/Quicksand-Bold.ttf') format('truetype'),
        url('../../../assets/fonts/Quicksand/Quicksand-Bold.woff2') format('woff2'),
        url('../../../assets/fonts/Quicksand/Quicksand-Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

// Raleway
@font-face {
    font-family: 'Raleway';
    src: url('../../../assets/fonts/raleway/Raleway-Regular.eot');
    src: url('../../../assets/fonts/raleway/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/raleway/Raleway-Regular.ttf') format('truetype'),
        url('../../../assets/fonts/raleway/Raleway-Regular.woff2') format('woff2'),
        url('../../../assets/fonts/raleway/Raleway-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('../../../assets/fonts/raleway/Raleway-Medium.eot');
    src: url('../../../assets/fonts/raleway/Raleway-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/raleway/Raleway-Medium.ttf') format('truetype'),
        url('../../../assets/fonts/raleway/Raleway-Medium.woff2') format('woff2'),
        url('../../../assets/fonts/raleway/Raleway-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('../../../assets/fonts/raleway/Raleway-SemiBold.eot');
    src: url('../../../assets/fonts/raleway/Raleway-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/raleway/Raleway-SemiBold.ttf') format('truetype'),
        url('../../../assets/fonts/raleway/Raleway-SemiBold.woff2') format('woff2'),
        url('../../../assets/fonts/raleway/Raleway-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('../../../assets/fonts/raleway/Raleway-Bold.eot');
    src: url('../../../assets/fonts/raleway/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../../assets/fonts/raleway/Raleway-Bold.ttf') format('truetype'),
        url('../../../assets/fonts/raleway/Raleway-Bold.woff2') format('woff2'),
        url('../../../assets/fonts/raleway/Raleway-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

$font-titles: 'Quicksand', sans-serif;
$font-primary: 'Raleway', sans-serif;