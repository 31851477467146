.dashboard {
    display:flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;

    &-cards {
        flex:1;
        margin-top:20px;
        width: 100%;
        display:flex; 
        flex-direction: row; 
        flex-wrap:wrap;
        justify-content: space-between
    }
}
