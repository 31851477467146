//FONT SIZES
$size-font-xxxs: 0.57rem;            //8px
$size-font-xxs: 0.71rem;            //10px
$size-font-xs: 0.86rem;             //12px
$size-font-s: 14px;                 //14px
$size-font-base: 1.14rem;           //16px
$size-font-m: 1.28rem;              //18px
$size-font-l: 1.43rem;              //20px
$size-font-xl: 1.71rem;             //24px
$size-font-xxl: 1.86rem;            //26px
$size-font-xxxl: 2.14rem;           //30px

// RADIUS SIZES
$size-border-radius-xs: 2px;
$size-border-radius-s: 5px;
$size-border-radius: 8px;
$size-border-radius-l: 12px;

// BREAKPOINTS
$breakpoint-desktop-small: 1300px;
$breakpoint-tablet: 1050px;
$breakpoint-tablet-portrait: 768px;
$breakpoint-mobile: 480px;