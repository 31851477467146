#header {
    background-color: $color-inner-bg;
    z-index: 5;
    position: fixed;
    top: 0;
    width: 100%;
    flex: none;
    height: 250px;
    @include transition((height, right), .3s);

    h2 {
        font-family: $font-titles;
        font-size: $size-font-base;
        color: $color-orange;
        text-align: center;
        margin-bottom: 5px;
    }

    h4 {
        font-family: $font-titles;
        font-size: $size-font-xs;
        color: #ffffff;
        text-align: center;
    }

    .img-user {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        border: 3px solid $color-border;
        overflow: hidden;
        margin: 0 auto 10px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.header-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 185px;
    border-bottom-right-radius: 50px;
    background: linear-gradient(180deg, #4b1e5e, #2d1b34);
    padding: 20px;
    position: relative;
    z-index: 5;
    @include transition(height, .3s);

    &:before {
        content: '';
        position: absolute;
        background: $vector-waves no-repeat center;
        background-size: 100% auto;
        top: 50%;
        left: 0;
        height: 100%;
        width: 100%;
        border-bottom-right-radius: 50px;
        transform: translateY(-50%);
        z-index: -1;
    }
}

.inner-wrap {
    position: relative;
    top: 0;
    opacity: 1;
    @include transition((top, opacity), .3s);
}

.journal-wrap {
    position: absolute;
    top: 88%;
    width: 100%;
    @include transition((top, width), .3s);

    &-img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        max-width: 43px;
        @include transition((max-width, left, transform), .3s);
    }

    p {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 55px;
        width: 80%;
        text-align: center;
        font-size: $size-font-xxs;
        text-transform: uppercase;
        color: $color-text-darkgray;
        @include transition(all, .3s);
    }
}

.settings-menu {
    position: absolute;
    right: 12px;
    top: 32px;
    
    width: 32px;
    height: 32px;
    border: 0;
    @include transition(top, .3s);
    background:none;
    display:flex;
    justify-content: center;
    align-items: center;
    &-icon {

        width: 16px;
        height: 16px;
        background: $icon-settings no-repeat center;
        background-size: contain;
    }
    
}

body.scrolled {
    #header {
        height: 68px;
    }
    
    .header-wrap {
        height: 68px;
    }

    .inner-wrap {
        top: -150px;
        opacity: 0;
    }

    .journal-wrap {
        top: 30%;
        width: calc(100% - 40px);

        p {
            color: #fff;
            left: 35px;
            top: 5px;
            text-align: left;
            transform: translateX(0);
        }
    }

    .journal-wrap-img {
        max-width: 25px;
        left: 0;
        transform: translateX(0);
    }
    
    .settings-menu {
        top: 17px;
    }
}