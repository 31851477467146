.diary-colorpicker {    
    &-wrapper {
        display: flex;
        align-items: center;
        margin: 20px 0;
    }
    
    &-radio {
        display: flex;
        margin-right: 16px;

        label {
            position: relative;
            margin: 0;
            width: 24px;
            height: 24px;
            cursor: pointer;

            &::before {
                content: "";
                @include absolute-center-center;
                width: 0;
                height: 0;
                border-radius: 50%;
                background-color: $color-text-lightgray;
                @include transition((width, height), .2s, cubic-bezier(.45, 1.8, .5, .75));
            }
        }

        &-color {
            position: absolute;
            background-color: $color-text-midgray;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            border-radius: 50%;
        }
    }

    input[type=radio] {
        display: none;
        opacity: 0;
        min-width: initial;

        &[disabled] + label {
            cursor: not-allowed;
            background-color: $color-text-midgray;
        }
        
        &:checked + label::before {
            width: 34px;
            height: 34px;
        }
    }
}