*::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: $font-primary;
}

*:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: $font-primary;
}

*::-ms-input-placeholder { /* Microsoft Edge */
    font-family: $font-primary;
}

p{
    font: 500 1rem $font-primary;
    line-height: $size-font-m;
    color: $color-text-gray;

    &.text-purple {
        font-size: $size-font-s;
        font-weight: 700;
        color: $color-lightpurple;
    }
}


span {
    color: $color-text-darkgray;
}

small{
    font: 500 $size-font-xxs $font-primary;

    &.small-red{
        color: $color-red;
        font-weight: 600;
    }

    &.small-gray {
        color: $color-text-darkgray;
    }
}

.text {
    &-success {
        color: $color-picker-green;
    }

    &-error {
        color: $color-red;
    }

    &-form-small {
        color: darken($color-dark-orange, 15%);
        font-size: $size-font-xs;
    }
}

.input-error-message {
    margin: 5px 0;
    font-size: $size-font-xs;
    font-weight: 600;
    color: #F00;

    a {
        font-size: $size-font-xs;
        font-weight: 600;
        color: #F00;
    }
}
