.toast {
    position: fixed;
    display: flex;
    width: 90%;
    max-width: 1300px;
    padding: 5px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: lighten($color-text-lightgray, 5%);
    color: $color-purple;
    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.1);
    border-bottom-left-radius: $size-border-radius-l;
    border-bottom-right-radius: $size-border-radius-l;
    transition: transform 220ms cubic-bezier(0.2, 0, 0, 1) 0s;
    z-index: 1000;

    &-success {
        color: $color-text-message;
    }

    &-error {
        color: $color-orange;

        .toast-content::before {
            background: $icon-alert no-repeat center;
            background-size: contain;
        }
    }

    &-global-error {
        color: $color-orange;

        .toast-content::before {
            background: $icon-alert no-repeat center;
            background-size: contain;
        }
    }

    &-warning {
        .toast-content::before {
            background: $icon-top-orange no-repeat center;
            background-size: contain;
        }
    }

    &-content {
        margin: 0 auto;
        padding: 8px 20px 8px 12px;
        font-family: $font-titles;
        text-align: center;
        min-height: 40px;

        &::before {
            content: '';
            display: inline-block;
            margin: 0 0.7em -0.2em -1em;
            width: 20px;
            height: 20px;
            font-size: 1rem;
        }
    }

    &-dismiss {
        position: absolute;
        right: 0;
        padding: 8px 12px;
        cursor: pointer;
    }

    .btn-close {
        color: currentColor;

        &::before,
        &::after {
            background-color: currentColor;
        }
    }
}
