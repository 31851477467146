.health-status-card {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: $size-border-radius-l;
    background-color: #FFF;
    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.1);
    padding: 16px 16px 8px;
    min-height: 100px;
    width: calc(50% - 8px);
    margin-bottom: 16px;
    cursor: pointer;

    span {
        font-size: $size-font-xs;
        font-weight: 700;
    }

    &-add {
        margin-left: auto;
        width: 24px;
        height: 24px;
        border: none;
        background: $icon-bulletplus-active no-repeat center;
        background-size: contain;

        &:focus {
            background: $icon-bulletplus-touched no-repeat center;
            background-size: contain;
        }
    }
}