.journal-card-wrapper {
    display: flex;
    max-width: 400px;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

// user commented if img width should be relative to screen width
.journal-card {
    min-width: 120px; //comment this out
    background-color: $color-light;
    border-radius: $size-border-radius;
    border: 3px solid $color-light;
    padding: 16px;
    width: calc(50% - 8px);
    margin-bottom: 16px;
    text-align: center;

    &.selected {
        border: 3px solid lighten($color-text-midgray, 20%);
    }

    &-img {
        position: relative;
        width: 72px;
        //max-width: 72px;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2);
        margin: 0 auto 8px;
    }

    &-img-user {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 28px;
        left: 25px;
        // width: 40%;
        // height: 36%;
        // top: 35%;
        // left: 35%;
        border-radius: 100%;
        overflow: hidden;
    }

    span {
        font: 700 $size-font-xxs $font-titles;
    }
}