#app {
    position: relative;
    display: flex;
    flex-direction: column;
    right: 0;
    overflow-x: hidden;
    transition: right 0.3s ease-in-out;
}

#primeContent {
    display: flex;
    border-top-left-radius: $size-border-radius-l;
    border-top-right-radius: $size-border-radius-l;
    overflow: hidden;
}

.container {
    width: 90%;
    max-width: $breakpoint-desktop-small;
    margin: 0 auto;

    @media only screen and (max-width:$breakpoint-tablet) {
        &.scrollable {
            width: auto;
            max-width: none;
        }
    }
}

.block {
    //background-color: #fff;
    padding: 0 20px;
    //border-radius: $size-border-radius-l;
    margin: 0 auto;
    //box-shadow: 0 2px 20px 0 rgba(0,0,0,0.1);
}
