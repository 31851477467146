.dropzone {
    border: 2px dashed transparent;
    border-radius: $size-border-radius-s;
    outline: none;
    @include transition(border, .25s);

    &-wrapper {
        display: flex;
        align-items: center;
        padding: 20px;
    }

    &-preview {
        background-color: $color-text-lightgray;
        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;
        outline: none;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
    }
}

.upload-diary-image {
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    height: 100%;

    .dropzone {
        margin: 10px 0;
        margin-right: -45px;

        &:hover {
            border-color: $color-orange;
        }

        &-placeholder {
            padding: 25px;
            flex: 1;
    
            h3 {
                font-size: $size-font-m;
            }
    
            p {
                margin-bottom: 15px;
                font-size: $size-font-s;
            }
        }
    }
}

.upload-profile-image {
    input[type="file"] {
        display: none;
    }

    .dropzone {
        &:hover {
            border-color: #fff;
        }

        &-wrapper {
            flex-direction: column;
        }

        &-preview {
            position: relative;
            width: 100px;
            height: 100px;
            border: 3px solid #fff;
        }

        &-placeholder {
            position: absolute;
            bottom: 15px;
            left: 50%;
            transform: translateX(-50%);
            padding: 3px 10px;
            background-color: $color-purple;
            border-radius: 5px;
            text-align: center;

            p {
                color: #fff;
                text-transform: uppercase;
                font: 700 $size-font-xxxs $font-titles;
            }
        }
    }

    .header {
        color: #fff;
        font: 700 $size-font-l $font-titles;
        text-align: center;
    }
}