.form-textarea{
    display: inline-flex;
    position: relative;
}

textarea{
    font: 400 1rem $font-primary;
    background-color: #fff;
    padding: 10px 0;
    border: none;
    width: 100%;
    min-height: 90px;
    resize: vertical;

    & input::placeholder{
        color: lighten($color-text-gray, 10%);
    }
}