.ReactModal {
    &__Overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.75);
        z-index: 9999;

        &-aside-overlay {
            position: static;
            background-color: transparent;
        }
    }

    &__Content {
        position: absolute;
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        border: 0;
        background: rgb(255, 255, 255);
        overflow: auto;
        border-radius: 4px;
        outline: none;
        padding: 50px 65px;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        width: 900px;
        max-height: 90%;
        box-shadow: 0 20px 80px 0 rgba(0,0,0,0.1);

        @extend %scrollbar;

        &--Large {
            width: 1300px;
        }

        &--Medium {
            width: 900px;
        }

        .react-modal {
            &-header{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 45px;
    
                &-title {
                    display: flex;
                    align-items: center;
    
                    &-icon {
                        margin-right: 20px;
                        margin-bottom: -2px;
                        width: 32px;
                    }
                }
            }

            &-footer {
                display: flex;
                justify-content: flex-end;
                margin-top: 35px;

                & > button {
                    margin-left: 16px;
                }
            }
        }



        // &-aside-popover {
        //     width: 100%;
        //     max-height: unset;
        //     padding: 20px;
        //     margin: 0;
        //     border-radius: $size-border-radius;
        //     border: 1px solid lighten($color-text-darkgray, 45%);
        //     bottom: unset;
        //     top: unset;
        //     left: 0;
        //     right: unset;
        //     transform: none;
        //     overflow: unset;

        //     @include transition((top, bottom), 200ms);

        //     .modal-header {
        //         align-items: flex-start;
        //         margin-bottom: 15px;
        //         padding: 0;
        //         border: none;
        //         color: lighten($color-text-message, 4%);
                
        //         .icon-help {
        //             font-size: $size-font-l;
        //         }
    
        //         h3{
        //             color: lighten($color-text-message, 4%);
        //             width: 90%;
        //             margin: 0 15px;
        //         }
        //     }

        //     &-bookmark {
        //         position: absolute;
        //         right: -9px;
        //         width: 16px;
        //         height: 16px;
        //         background-color: #fff;
        //         border-top: 1px solid lighten($color-text-darkgray, 45%);
        //         border-right: 1px solid lighten($color-text-darkgray, 45%);
        //         transform: rotate(45deg);
        //         z-index: 99;
        //     }
        // }
    }
}
