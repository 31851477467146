h1 {
    font: 700 $size-font-l $font-titles;
    color: $color-orange;
}

h2 {
    font: 700 $size-font-m $font-primary;
    color: $color-text-darkgray;
}

h3 {
    font: 700 $size-font-base $font-titles;
    color: $color-purple;
    margin: 15px 0 30px;
}

h4 {
    font: 700 $size-font-xs $font-titles;
    color: $color-blue;
}

h5 {
    font: 700 $size-font-xs $font-primary;
    color: $color-text-darkgray;
}