// CENTERING

@mixin absolute-center-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin absolute-right-center {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

@mixin absolute-left-center {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

// TRANSITION

@mixin transition($property, $interval, $easing: ease-in-out, $delay: 0s) {
    transition-property: $property;
    transition-duration: $interval;
    transition-timing-function: $easing;
    transition-delay:$delay;
}

// // BREAKPOINTS

// @mixin breakpoint($screenWidth) {
// 	@if $screenWidth == 1100 {
// 		@media all and (max-width: 1100px) { @content ; }
// 	}
// 	@else if $screenWidth == 800 {
// 		@media all and (max-width: 800px) { @content ; }
// 	}
// }

// SELECT ELEMENT STYLE
@mixin customSelect($class-prefix){
    #{$class-prefix}__control{
        border: 2px solid $color-blue-light;
        border-radius: $size-border-radius;
        line-height: 22px;
        min-height: 46px;
    }

    #{$class-prefix}__indicator-separator{
        background-color: transparent;
    }

    #{$class-prefix}__dropdown-indicator{
        color: $color-orange;
    }

    #{$class-prefix}__placeholder{
        color: lighten($color-text-gray, 10%);
    }
}
