.SingleDatePickerInput{
    display: flex;
    align-items: center;
    border: 2px solid $color-blue-light;
    line-height: 22px;
    min-height: 46px;

    .DateInput{
        width: 100%;
    }
}

.SingleDatePicker_picker{
    z-index: 10;
}

.SingleDatePickerInput__withBorder{
    overflow: hidden;
    border-radius: $size-border-radius;
}

.DateInput_input {
    font-size: 1rem;
    font-weight: 400;
    padding: 5px;
    color: #000;
}

.DateInput_input__focused {
    border-bottom: 2px solid $color-blue;
}

.CalendarDay__default {
    border: 1px solid #e4e7e7;
    color: $color-text-gray;
    background: #fff;

    &:hover{
        background: $color-main-bg;
        border-color: $color-main-bg;
        color: inherit;
    }
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:hover {
    border: 1px solid #e4e7e7;
    color: $color-text-gray;
    background: #f7f7f7;
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
    background: $color-blue;
    border: 1px solid $color-blue;
    color: white;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
    background: $color-orange;
    border: 1px solid $color-orange;
    color: white;
}

.CalendarDay__selected_span {
    background: $color-blue-light;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: $color-blue-light;
}

// DATE RAGE PICKER
.DateRangePicker{
    width: 100%;

    .DateRangePickerInput{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border: 2px solid $color-blue-light;
        border-radius: $size-border-radius;
        line-height: 22px;
        padding: 0;

    }
    .date-picker-range{
        padding: 10px 22px 22px;
    }

    .DateInput{
        width: initial;
        background-color: transparent;
    }

    .DateRangePickerInput_arrow{
        margin: 0 5px;
    }

    .DateInput_input {
        background-color: transparent;
        padding: 8px 5px;
        text-align: center;
        min-height: 0 !important;
        max-height: none;
    }

    .DateRangePickerInput_clearDates{
        display: flex;
        align-items: center;
        position: static;
        transform: none;
        margin: 0;
        opacity: 1;
        @include transition((opacity, margin-right), .2s);

        &__hide{
            margin-right: -40px;
            opacity: 0;
            visibility: unset;
            pointer-events: none;
        }

        &_default:focus,
        &_default:hover{
            background: transparent;
            border-radius: unset;

            svg{
                fill: $color-orange;
            }
        }
    }
}

.DateRangePickerInput_arrow_svg {
    fill: $color-orange;
}
