.journal-collection {
    height: 100%;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 80px;
        background: linear-gradient(0deg, #EDEDED 0%, rgba(216,216,216,0) 100%);
        top: -26px;
        left: 0;
        z-index: 1;
    }

    &-wrapper {
        position: relative;
        height: 100%;
        border-top-left-radius: 55px;
        background: linear-gradient(284deg, $color-orange 0%, $color-lightorange 100%);
        overflow: hidden;
        z-index: 10;

        &::before {
            content: "";
            position: absolute;
            background-color: rgba(255,255,255,0);
            width: 100%;
            height: 100%;
            z-index: -1;
            @include transition(background-color, .2s);
        }
    }
    
    &-header {
        padding: 16px;
        text-align: center;
        
        h5 {
            font-weight: 600;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
        }
        
        .svg-inline {
            display: inline-block;
            margin: 6px auto 0;
            color: $color-text-darkgray;
            font-size: $size-font-xxs;
            @include transition(transform, .2s);
        }
    }
    
    &-body {
        height: 90%;
        padding: 20px 30px;
        overflow-y: auto;
    }
}

#footer.journals-open {
    .journal-collection {
        &-wrapper::before {
            background-color: rgba(255,255,255, 1);
        }

        &-header {
            h5 {
                color: $color-text-darkgray;
            }

            .svg-inline {
                color: $color-orange;
                transform: rotate(180deg);
            }
        }
    }
}