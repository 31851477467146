.input-checkbox{
    @extend %radio-checkbox;
    margin: 10px 0;

    input[type=checkbox] + label{
        &::before{
            left: 5px;
            top: calc(50% - 2px);
            transform: translateY(-50%) rotate(-45deg) scale(0,0);
            border: 2px solid #fff;
            border-top-style: none;
            border-right-style: none;
        }
    }

    input[type=checkbox]{
        min-width: initial;
        
        &:checked + label::before {
            transform: translateY(-50%) rotate(-45deg) scale(1,1);
        }

        &:checked + label::after {
            background: $color-orange;
        }
    }
}