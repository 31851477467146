//INPUT FIELDS
%radio-checkbox {
    .input-wrapper {
        display: flex;
        align-items: center;
        min-height: 46px;
    }

    label {
        position: relative;
        padding-left: 43px;
        margin: 0;
        color: $color-text-darkgray;
        font-weight: 500;
        font-size: 1rem;
        cursor: pointer;

        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            width: 10px;
            height: 5px;
            transition: transform .4s cubic-bezier(.45, 1.8, .5, .75);
        }

        &::after {
            content: "";
            @include absolute-left-center;
            border-radius: $size-border-radius-xs;
            width: 18px;
            height: 18px;
            background: #fff;
            border: 2px solid $color-orange;
            @include transition(all, .2s);
        }
    }

    input[type=radio],
    input[type=checkbox] {
        display: none;
        opacity: 0;

        &[disabled] + label {
            cursor: not-allowed;
            color: $color-text-midgray;

            &::after {
                border-color: $color-text-midgray;
            }
        }
    }
}

//TEXT ELLIPSIS
%ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

//SCROLLBARS
%scrollbar {
    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        border-radius: $size-border-radius;
        background-color: $color-text-lightgray;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: $size-border-radius;
        background-color: $color-orange;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $color-red;
    }

    &::-webkit-scrollbar-thumb:window-inactive {
        background-color: $color-text-lightgray;
    }
}