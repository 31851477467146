.quicklink,
.quicklink-cta {
    display: flex;
    align-items: center;
    padding: 10px 30px;
    margin: 10px 0;
    border-radius: $size-border-radius-l;
    min-width: 300px;
    min-height: 80px;
    box-sizing: border-box;
    word-break: break-word;

    &-wrap {
        width: 100%;
        position: relative;

        &::after {
            content: '';
            @include absolute-right-center;
            transform: translateY(-50%) rotate(-45deg);
            border-bottom: 2px solid $color-orange;
            border-right: 2px solid $color-orange;
            width: 8px;
            height: 8px;
            @include transition(right, .2s);
        }
    }

    &:hover {
        .quicklink-cta-wrap::after,
        .quicklink-wrap::after  {
            right: -10px;
        }
    }
}

.quicklink {
    border: 3px solid $color-text-lightgray;
    background-color: #fff;

    &-wrap {
        padding-left: 30px;

        span {
            font: 700 1rem $font-titles;
        }

        img {
            @include absolute-left-center;
            height: 22px;
            width: 22px;
        }
    }

    &:hover{
        background-color: rgba(#000, .1);
        border: 3px solid $color-text-midgray;
    }
}

.quicklink-cta {
    background: linear-gradient(45deg, $color-lightorange, $color-orange);

    &-wrap {
        h4 {
            color: #fff;
        }

        small.small-gray {
            font-size: $size-font-xs;
        }
        
        &::after {
            border-color: #fff;
        }
    }
}