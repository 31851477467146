// PRIMARY

$color-orange: #E24524;
$color-purple: #442F4D;

// BACKGROUND
$color-inner-bg: #EDEDED;

// Profile
$color-border: #D4B8DF;

// TEXT COLOR
$color-text-lightgray: #E1E1E1;
$color-text-midgray: #AEADAC;
$color-text-gray: #676767;
$color-text-darkgray: #3B2E40;
$color-text-darkblue: #095584;
$color-text-message: #60BC10;

// COLORPICKER
$color-picker-red-orange: #E5572A;
$color-picker-green: #A8BB49;
$color-picker-purple: #C078CE;
$color-picker-turquoise: #53D9F7;
$color-picker-yellow: #D3AB03;
$color-picker-brown: #5A2314;

// SECONDARY
$color-light: #F9F4FB;
$color-yellow: #F5BB41;
$color-dark-orange: #BD3A1F;
$color-lightorange: #FF6E00;
$color-blue: #0280AA;
$color-darkpurple: #2B0E35;
$color-lightpurple: #9359A9;
$color-midpurple: #723C84;
$color-red: #F35E3E;
$color-blue-light: #C2E4FC;
$color-blue-tab: #56A2D1;
$color-main-bg: #DCEAF3;
$color-upload-bg: #F7FCFF;
$color-login-title: #0073BC;